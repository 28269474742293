import ApiInstance from "./ApiInstance";
import { Questionnaire } from "../types/Types";


class QuestionnaireApi {
  public async getQuestionnaire(questionnaireType): Promise<Questionnaire> {
    const response = await ApiInstance.getApi().get("/questionnaire/" + questionnaireType);
    return response.data;
  }
}

export default new QuestionnaireApi();
