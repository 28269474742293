import axios from "axios";
import { Config } from "../Config";

class ApiInstance {
  public getApi() {
    const instance = axios.create({
      baseURL: Config.getBackendUrl(),
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true  // Ensure credentials (cookies) are sent with requests
    });

    return instance;
  }
}

export default new ApiInstance();
