import React, { useState, useEffect, useRef } from 'react';
import './Hulpverlener.css';
import Header from '../../components/header/Header'; // Adjust the path as necessary
import Footer from '../../components/footer/Footer'; // Import the Footer component
import Lottie from 'lottie-react';
import hulpverlenerAnimation from '../../animations/hulpverlener.json'; // Adjust path accordingly
import gratisImage from '../../animations/100gratis.json'; // Adjust path accordingly
import miaProfielImage from '../../assets/miaprofiel.svg'; // Adjust path accordingly
import miaPraktijkImage from '../../assets/miapraktijk.svg'; // Adjust path accordingly
import providerImage from '../../assets/hulpverlener.png';
import comonImage from '../../assets/comon.webp';
import imecImage from '../../assets/imec.webp';
import gentImage from '../../assets/logogent.png';
import univGentImage from '../../assets/ugent.png';
const Hulpverlener: React.FC = () => {


  const [scrollY, setScrollY] = useState(0);
  const [parallaxHeight, setParallaxHeight] = useState('auto');


  const handleScroll = () => {
    setScrollY(window.scrollY);
  };


  const handleStarterButtonClick = () => {
    window.location.href = "https://app.miapraktijk.be/api/public/stripe/miaprofiel/checkoutsession";
  };

  const handleStandardButtonClick = () => {
    window.location.href = "https://app.miapraktijk.be/api/public/stripe/miaunlimited/checkoutsession";
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  const handleFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if ((videoRef.current as any).webkitRequestFullscreen) { /* Safari */
        (videoRef.current as any).webkitRequestFullscreen();
      } else if ((videoRef.current as any).msRequestFullscreen) { /* IE11 */
        (videoRef.current as any).msRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    
  }, []);

  
  useEffect(() => {
    const parallaxElement = document.querySelector('.paralax');
    if (parallaxElement) {
      const rect = parallaxElement.getBoundingClientRect();
      let heightAdjustment = window.innerHeight - rect.top - window.scrollY;
      if(heightAdjustment < 600)
        heightAdjustment = 600;
      setParallaxHeight(`${heightAdjustment}px`);
    }
  }, [scrollY]);

  return (
    <div className="container">
      <Header/>
      
      <div className="top-image-wrapper">
        <Lottie className="hulpverlener-image" animationData={hulpverlenerAnimation} loop={true} />
        <Lottie className="gratis-image" animationData={gratisImage} loop={true} />
      </div>
      <main className="main-hulpverlener">
        <div className="content-wrapper-hulpverlener">
          <div className="text-section-hulpverlener">

                  <h2>Mia Profiel</h2>
                  <p className="subtitle">Jouw profiel op miazoekthulp.be</p>
                  <p>Krijg enkel de cliënten die bij jou passen<br/>
                    Enkel cliënten wanneer jouw wachtlijst dit toelaat<br/>
                    Minder tijd verliezen aan doorverwijzingen<br/>
                    Bied ook cursussen en workshops aan
                    </p>
           </div>
          
        </div>
        
      </main>
     
      <div className="paralax" style={{ transform: `translateY(-${scrollY * 0.5}px)`, height: parallaxHeight }}>
      <div className='hulpverlener-buy-section'>
      <div className="promo-section">
       
        <div className="package-title">
          <h2>Aansluiten als hulpverlener is gratis</h2>
          <p>Mia zal actief worden gesteld voor hulpzoekers vanaf we beschikken over een aanbod van 100 hulpverleners. <br/>Daarom willen wij de eerste 100 hulpverleners bedanken met een permanent gratis profiel. </p>
        </div>
      </div>
      <div className="panel-container">
        <div className="panel mia-starter">
          <h2 className="panel-title">Mia Starter</h2>
          <p>Een hulpverlener profiel op miazoekthulp.be</p>
          <h4 className="panel-subtitle">ACTIE EERSTE 100 PROFIELEN!</h4>
          <div className="panel-content">
            <p>Permanent gratis Mia Profiel</p>
            <p><b>Mia Profiel</b> - voor altijd gratis</p>
            <p>Hulp aanbieden via de Mia website met jouw profiel tussen de resultaten.</p>
           <br/>
          </div>
          <div className="panel-footer">
            <button className="panel-button" onClick={handleStarterButtonClick}>Permanent gratis</button>
          </div>
        </div>
      </div>
      </div>
      <div className="sponsors">
        <h2>Met ondersteuning van onze partners</h2>
        <p>
          Deze slimme oplossing is bedacht in de schoot van Comon, een initiatief van de stad Gent in samenwerking met Imec. Tijdens een van de Miniproeftuinen over verstaanbare zorg heeft een divers team dit idee verder ontwikkeld tot een werkend prototype. Met dank aan deze partners:
          <br/><br/>
          <div className="sponsors-images">
          <img src={imecImage} alt="Imec" width="200"/>
          <img src={comonImage} alt="Comon" width="100"/>
          <img src={gentImage} alt="Stad Gent" width="100"/>
          <img src={univGentImage} alt="Universiteit Gent" width="100"/>
          </div>
        </p>
      </div>

      <Footer /> {/* Add the Footer component here */}
      </div>
    </div>
  );
};

export default Hulpverlener;
